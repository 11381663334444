import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from '~/components/Layout';

import { ArticleMasthead } from '../components/article/ArticleMasthead';
import { ArticleShareBar } from '../components/article/ArticleShareBar';
import ArticleRenderer from '../components/article/ArticleRenderer';
import { ArticleRelatedResources } from '../components/article/ArticleRelatedPosts';

import 'swiper/css/pagination';
import SeoOverride from '../components/global/SeoOverride';

const SingleResource = ({ data: { wpMarketCommentaryPost, wpPage, wp } }) => {
  const article = wpMarketCommentaryPost.postTypeSingle.article;
  const author = wpMarketCommentaryPost.author.node.userDisplayInformation.userDisplayInformation;
  const categories = wpMarketCommentaryPost.commentaryTags.nodes;

  let ogDescription = wpMarketCommentaryPost?.seo?.opengraphDescription;
  let ogImage = '';
  if (wpMarketCommentaryPost && wpMarketCommentaryPost.seo && wpMarketCommentaryPost.seo.opengraphImage) {
    ogImage = wpMarketCommentaryPost.seo.opengraphImage.sourceUrl;
  } else {
    ogImage = 'https://repool.wpengine.com/wp-content/uploads/2022/06/OpenGraph.png';
  }

  return (
    <Layout wp={wp} darkHeader={true}>
      <SeoOverride post={wpMarketCommentaryPost} />
      <ArticleMasthead
        wp={wp}
        categories={categories}
        author={author}
        date={wpMarketCommentaryPost?.date}
        content={wpMarketCommentaryPost?.postTypeSingle?.masthead}
        returnLink={'/the-deep-end/'}
      />
      <ArticleRenderer
        content={article?.articleBlocks}
        prefix={'MarketCommentaryPost_Posttypesingle_Article_ArticleBlocks_'}
      />
      <div
        className={`container mb-8 md:mb-12 ${wpMarketCommentaryPost.postTypeSingle?.relatedPosts && 'lg:mb-[72px]'}`}
      >
        <div className="max-w-[50rem] mx-auto pt-8 md:pt-[40px] border-y border-borderGrey">
          <ArticleShareBar />
        </div>
      </div>
      {wpMarketCommentaryPost.postTypeSingle?.relatedPosts ? (
        <ArticleRelatedResources
          relatedItem={wpMarketCommentaryPost?.postTypeSingle?.relatedPosts}
          relatedHeading={wpMarketCommentaryPost?.postTypeSingle?.relatedHeading}
        />
      ) : (
        <div className="h-[30px] md:h-[70px]" />
      )}
    </Layout>
  );
};

export default SingleResource;

export const pageQuery = graphql`
  query SinglePost($id: String!) {
    wp {
      ...GeneratedWp
      header {
        globalUpdatedHeader {
          fieldGroupName
          headerUpdated {
            topLevelNavigation {
              link {
                title
                target
                url
              }
              addMenu
              subMenu {
                tabs {
                  heading
                  description
                  menuType
                  columnMenu {
                    columns {
                      heading
                      cards {
                        image {
                          ...Image
                        }
                        link {
                          title
                          url
                        }
                        description
                      }
                    }
                  }
                  rowMenu {
                    heading
                    cards {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                  resourcesMenu {
                    listSubheading
                    listItems {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                    }
                    cardsSubheading
                    cardItems {
                      thumbnail {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                }
              }
            }
            buttons {
              textLink {
                target
                title
                url
              }
              buttonLink {
                target
                title
                url
              }
            }
            socials {
              image {
                ...Image
              }
              link {
                target
                title
                url
              }
            }
          }
        }
      }
    }
    wpMarketCommentaryPost(id: { eq: $id }) {
      title
      uri
      date
      commentaryTags {
        nodes {
          name
        }
      }

      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          localFile {
            publicURL
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      author {
        node {
          name
          userDisplayInformation {
            userDisplayInformation {
              displayName
              bio
              profilePicture {
                altText
                publicUrl
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
      postTypeSingle {
        previewgroup {
          previewDescription
        }
        relatedHeading
        relatedPosts {
          postItem {
            ... on WpMarketCommentaryPost {
              id
              date
              slug
              title
              uri
              featuredImage {
                node {
                  altText
                  publicUrl
                  sourceUrl
                  localFile {
                    publicURL
                  }
                }
              }
              postTypeSingle {
                previewgroup {
                  previewDescription
                }
              }
            }
          }
        }
        masthead {
          addImage
          image {
            altText
            publicUrl
            sourceUrl
            localFile {
              publicURL
            }
          }
          useCategoryAsSubheading
          subheading
          heading
          description
          readTime
        }
        article {
          articleBlocks {
            ... on WpMarketCommentaryPost_Posttypesingle_Article_ArticleBlocks_Text {
              fieldGroupName
              heading
              text
            }
            ... on WpMarketCommentaryPost_Posttypesingle_Article_ArticleBlocks_Image {
              fieldGroupName
              image {
                ...Image
              }
            }
            ... on WpMarketCommentaryPost_Posttypesingle_Article_ArticleBlocks_Quote {
              fieldGroupName
              quote
              addContext
              by {
                name
                role
              }
            }
            ... on WpMarketCommentaryPost_Posttypesingle_Article_ArticleBlocks_Bullets {
              fieldGroupName
              heading
              bullets {
                text
              }
            }
          }
        }
      }
    }
  }
`;
